<template>
  <ion-page>
    <ion-header class="ion-padding-horizontal white-background-color">
      <ion-toolbar>
      </ion-toolbar>
      <ion-toolbar class="ion-padding-bottom">
        <z-filter @filterActived="onFilterChanged($event)" title="My collection" module="collection" />
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <section>
        <div v-if="isLoading" class="load-wrap ion-text-center">
          <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
          <p>Loading...</p>
        </div>

        <z-pin-list v-if="pins.length" module="collection" :pins="pins" />
        <div v-if="!isLoading && pins.length == 0 && activeFilter == true"  class="ion-padding ion-text-center">There are no pins matching your search criteria.</div>
        <div v-if="!isLoading && pins.length == 0 && !activeFilter" class="ion-padding ion-text-center">There are no pins in your collection yet.</div>
      </section>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
  import {mapGetters, mapActions} from 'vuex'
  import { IonPage, IonContent, IonHeader, IonToolbar } from '@ionic/vue'

  export default {
    components: {
      IonPage, IonContent, IonHeader, IonToolbar
    },
    data() {
      return {
        activeFilter: false
      }
    },

    watch: {
      $route() {
        if (this.$route.fullPath.includes('my-collection'))
          this.fetchByPage(1)
      }
    },

    mounted() {
      this.fetchByPage()
    },

    computed: {
      ...mapGetters('collection', ['pins', 'isLoading'])
    },

    methods: {
      ...mapActions('collection', ['fetchByPage']),

      onFilterChanged(data) {
        this.activeFilter = data.active
      }
    }
  }
</script>

<style lang="sass" scoped>
.white-background-color
  background: white
.logo
  position: absolute
  top: 60px
  z-index: 1
  img
    margin-right: 15px

ion-page
  --background: #fff

ion-header
  background: #fff

</style>
