
  import {mapGetters, mapActions} from 'vuex'
  import { IonPage, IonContent, IonHeader, IonToolbar } from '@ionic/vue'

  export default {
    components: {
      IonPage, IonContent, IonHeader, IonToolbar
    },
    data() {
      return {
        activeFilter: false
      }
    },

    watch: {
      $route() {
        if (this.$route.fullPath.includes('my-collection'))
          this.fetchByPage(1)
      }
    },

    mounted() {
      this.fetchByPage()
    },

    computed: {
      ...mapGetters('collection', ['pins', 'isLoading'])
    },

    methods: {
      ...mapActions('collection', ['fetchByPage']),

      onFilterChanged(data) {
        this.activeFilter = data.active
      }
    }
  }
